// ==============================
// * PAGE DESIGNER: HERO SLIDER CONTENT
//
// ? This file gathers all the hero slider content CSS.
// ==============================

@import "../../../variables";
@import "bootstrap/scss/variables";
@import "../../../abstract/mixins";
@import "bootstrap/scss/mixins/breakpoints";

.hero-slider-content {
    &__item {
        position: relative;
        width: 100%;
        overflow: hidden;

        @include media-breakpoint-up(lg) {
            height: 600px;
        }
    }

    &__image {
        width: 100%;
        height: 460px;
        object-fit: cover;

        @include media-breakpoint-up(lg) {
            height: 100%;
        }
    }

    &__content {
        width: 100%;
        padding: $grid-gutter-width;
        background-color: $light-gray;

        @include media-breakpoint-down(lg) {
            max-width: none;
        }

        @include media-breakpoint-up(lg) {
            @include element-centered-X-Y;

            z-index: 1;
            padding: 0 50px;
            background-color: transparent;
            color: $white;

            &.light {
                color: $black;
            }

            &.left {
                text-align: left;
            }

            &.center {
                text-align: center;
            }

            &.right {
                text-align: right;
            }
        }

        .surtitle {
            margin-bottom: 10px;
            font-size: $font-size-sm;
            text-transform: uppercase;
        }

        .title {
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        &--btns {
            padding-top: 20px;

            .btn {
                margin: 0 10px 10px 0;

                &-outline {
                    border-color: $black;
                    background-color: $black;
                    color: $white;

                    @include media-breakpoint-up(lg) {
                        @include button-variant(transparent, $white, $hover-background: transparent, $hover-border: $white, $active-background: transparent, $active-border: $white);

                        &:hover,
                        &:focus,
                        &:active,
                        &.active {
                            border-color: $white;
                            background-color: $white;
                            color: $black;
                            opacity: 0.8;
                        }
                    }

                    &:hover,
                    &:focus,
                    &:active,
                    &.active {
                        border-color: $black;
                        background-color: $black;
                        color: $white;
                    }
                }
            }
        }
    }
}
